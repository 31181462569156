<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="officer-material" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.material_officer_ln') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <b-button variant="primary" @click="pdfDownload" class="ml-1">
                <i class="fas fa-print"></i> {{  $t('globalTrans.download') }}
            </b-button>
            <b-button variant="primary" @click="wordExport" class="ml-1">
                <i class="fas fa-print"></i> {{  $t('globalTrans.word') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                          {{ ''}}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <!-- <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ honorariumSheet.circular_memo_no }}</td> -->
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? honorariumSheet.fiscal_year_bn : honorariumSheet.fiscal_year }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_type_bn : honorariumSheet.training_type }}</td>
                      </tr>
                      <!-- <tr>
                        <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                        <td style="width: 32%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? honorariumSheet.org_bn : honorariumSheet.org }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.office_type') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.office_type_bn : honorariumSheet.office_type }}</td>
                      </tr> -->
                      <tr>
                        <!-- <th style="width: 15%"  class="text-left">{{ $t('globalTrans.office') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.office_bn : honorariumSheet.office }}</td> -->
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_category_bn : honorariumSheet.training_category }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ honorariumSheet.circular_memo_no }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_iabm.batch_no') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.batch_bn : honorariumSheet.batch }}</td>
                      </tr>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_iabm.training_start_dates') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ honorariumSheet.training_start_date | dateFormat }}</td>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_iabm.training_end_dates') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ honorariumSheet.training_end_date | dateFormat }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-overlay :show="load">
                <b-row id="form" v-if="materials.length">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{ $route.query.payment_type == '2' ? $t('elearning_tim.master_roll_materials_distribution_trainees') : $t('elearning_tim.master_roll_materials_distribution_trainers') }}</legend>
                      <div class="table-responsive">
                        <b-table-simple class="table" style="width:100%" bordered>
                          <b-thead class="thead">
                            <b-tr>
                              <th class="text-center" style="width:5%" rowspan="2">{{$t('globalTrans.sl_no')}}</th>
                              <th class="text-center" style="width:12%" rowspan="2">{{$t('globalTrans.name')}}</th>
                              <th class="text-center" style="width:12%" rowspan="2">{{$t('elearning_tim.designation&office')}}</th>
                              <b-th class="text-center" :colspan="materials.length">{{$t('elearning_tim.materials')}}</b-th>
                              <th class="text-center" style="width:10%" rowspan="2">{{$t('globalTrans.signature')}}</th>
                            </b-tr>
                            <b-tr>
                              <b-th v-for="(material, index1) in materials" :key="index1" style="vertical-align: middle" class="text-center" width="5%">
                                {{ $i18n.locale === 'bn' ? material.name_bn : material.name }}
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tr v-for="(data, index) in trainees" :key="index">
                            <b-td class="text-center" >
                              {{ $n(index + 1) }}
                            </b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? data.name_bn : data.name }}
                            </b-td>
                            <b-td>
                              {{ (($i18n.locale==='bn') ? data.designation_name_bn : data.designation_name) + ', ' + (($i18n.locale==='bn') ? data.work_place_name_bn : data.work_place_name) }}
                            </b-td>
                            <b-td class="text-center" v-for="(material, index1) in materials" :key="index1">
                              {{ $n(material.quantity) }}
                            </b-td>
                            <b-td></b-td>
                          </b-tr>
                        </b-table-simple>
                      </div>
                    </fieldset>
                  </div>
                </b-row>
                <h4 v-else class="text-center">{{ $t('elearning_tim.no_material_found') }}</h4>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{trainees}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
// import ExportPdf from './export_pdf_details'
import { materialDetails, trainingMaterialMasterRollWord } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo,
            payment_type: this.$route.query.payment_type,
            batch_no: this.$route.query.batch_no
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, materialDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
          window.vm.$toast.error({
              title: 'Sorry',
              message: 'Data not found'
            })
        } else {
            this.honorariumSheet = result.data[0]
            this.getCustomDataZeroList(result.training)
            const trainees = result.data
            this.trainees = this.getCustomDataList(trainees)
            this.materials = result.matherial
        }
        this.load = false
      }
    },
    // pdfExport () {
    //   const reportTitle = ''
    //   const noteTitle = this.$route.query.payment_type === 'Trainee' ? this.$t('elearning_tim.master_roll_materials_distribution_trainees') : this.$t('elearning_tim.master_roll_materials_distribution_trainers')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.trainees, noteTitle, 1)
    // },
    // pdfDownload () {
    //   const reportTitle = ''
    //   const noteTitle = this.$route.query.payment_type === 'Trainee' ? this.$t('elearning_tim.master_roll_materials_distribution_trainees') : this.$t('elearning_tim.master_roll_materials_distribution_trainers')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.trainees, noteTitle, 2)
    // },
    async pdfExport () {
      const circularMemoNo = {
        role: this.$store.state.Auth.activeRoleId,
        circular_memo_no: this.$route.query.circilarMemoNo,
        payment_type: this.$route.query.payment_type,
        batch_no: this.$route.query.batch_no
      }
      const params = Object.assign({ request_type: 'material_pdf', local: this.$i18n.locale, org_id: this.$store.state.Auth.authUser.org_id }, circularMemoNo)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, materialDetails, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfDownload () {
      const circularMemoNo = {
        role: this.$store.state.Auth.activeRoleId,
        circular_memo_no: this.$route.query.circilarMemoNo,
        payment_type: this.$route.query.payment_type,
        batch_no: this.$route.query.batch_no
      }
      const params = Object.assign({ request_type: 'material_pdf', local: this.$i18n.locale, org_id: this.$store.state.Auth.authUser.org_id }, circularMemoNo)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, materialDetails, params)
        const blob = new Blob([result], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)

        // Create a temporary anchor element
        const a = document.createElement('a')
        a.href = url
        a.download = 'document.pdf' // Set the file name for the download
        document.body.appendChild(a)
        a.click() // Trigger the download
        document.body.removeChild(a) // Remove the anchor element
        window.URL.revokeObjectURL(url) // Free up memory
      } catch (error) {
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async wordExport () {
      const search = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        org_id: 12,
        circular_memo_no: this.$route.query.circilarMemoNo,
        batch_no: this.$route.query.batch_no,
        payment_type: this.$route.query.payment_type
      }
      const params = Object.assign({ request_type: 'word', local: this.$i18n.locale }, search)
      this.load = true
      try {
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainingMaterialMasterRollWord, params)
              // Create a Blob and object URL
              var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'training_material_master_roll.docx' // Set the desired filename with .docx extension for Word documents

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        this.load = false
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (parseInt(item.not_here_designation) === 0 && parseInt(item.p_profession_type) === 1) {
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
          } else {
            desigData.designation_name = item.p_designation_en
            desigData.designation_name_bn = item.p_designation_bn
          }
          const workPlaceObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.p_office_id))
          const workPlaceData = {}
          if (parseInt(item.p_profession_type) === 1 && parseInt(item.not_here_office) === 0) {
            if (typeof workPlaceObj !== 'undefined') {
              // workPlaceData.work_place_name = workPlaceObj.text_en + ', ' + item.office_mail_address
              // workPlaceData.work_place_name_bn = workPlaceObj.text_bn + ', ' + item.office_mail_address_bn
              workPlaceData.work_place_name = workPlaceObj.text_en + (item.office_mail_address ? ', ' + item.office_mail_address : '')
              workPlaceData.work_place_name_bn = workPlaceObj.text_bn + (item.office_mail_address_bn ? ', ' + item.office_mail_address_bn : '')
            } else {
              workPlaceData.work_place_name = ''
              workPlaceData.work_place_name_bn = ''
            }
          } else if (parseInt(item.p_profession_type) === 1 && item.not_here_office === 1) {
            workPlaceData.work_place_name = item.p_office_name
            workPlaceData.work_place_name_bn = item.p_office_name_bn
          } else {
            workPlaceData.work_place_name = item.p_office_name
            workPlaceData.work_place_name_bn = item.p_office_name_bn
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, customItem, desigData, workPlaceData)
        })
        return listData
      },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          if (typeof batchObj !== 'undefined') {
            this.honorariumSheet.batch = batchObj.text_en
            this.honorariumSheet.batch_bn = batchObj.text_bn
          } else {
            this.honorariumSheet.batch = ''
            this.honorariumSheet.batch_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
